import React from "react"

// let today = new Date().toISOString().substr(0, 10)
// document.querySelector("#today").value = today

const hosting = () => {
  // document.querySelector("#today2").valueAsDate = new Date();

  return (
    <>
      <section className="agreement-copy">
        <h2>InfoArc LLC Web Hosting Agreement</h2>
        <h3>1. Purpose</h3>
        <p>
          This document sets out what levels of availability and support the
          client is guaranteed to receive as part of the Web Hosting service
          provided by InfoArc LLC. Please note that InfoArc LLC do offer
          multiple hosting packages each offering differing levels of developer
          support. On the standard hosting package for example, developer
          support (both telephone and email) is restricted to bug fixes in
          design/functionality developed by InfoArc LLC. Support requests not
          relating to site maintenance and fixes will be charged for at our
          standard hourly rate. Clients on the Premium package will receive 1
          hour developer support each month which can be used for wider site
          enhancements and functionality updates as required. The purpose of
          this Agreement is to ensure that the proper elements and commitments
          are in place to provide consistent support and maintenance for website
          hosting and the website to the Client(s) by the Service Provider(s).
          The objectives of this Agreement are to:
        </p>
        <ul>
          <li>
            Provide clear reference to service ownership, accountability, roles
            and/or responsibilities.
          </li>
          <li>
            Present a clear, concise and measurable description of website
            hosting to the client.
          </li>
          <li>
            Match perceptions of expected service provision with actual service
            support and delivery for both the website and website hosting.
          </li>
        </ul>
        <h3>2. Service Agreement</h3>
        <p>
          The following detailed service parameters are the responsibility of
          InfoArc LLC in the ongoing support of this Agreement.
        </p>
        <h3>Service Scope</h3>
        <p>The following Services are covered by this Agreement;</p>
        <ul>
          <li>Manned telephone support</li>
          <li>Monitored email support</li>
          <li>Software and security updates</li>
          <li>Regular backups of website</li>
          <li>
            Facilitating client penetration and other security testing and
            auditing, and resolving the results of those tests
          </li>
          <li>Bug fixes in design/functionality developed by InfoArc LLC</li>
        </ul>
        <h3>2.2 What is required by the Client</h3>
        <p>
          The Client responsibilities and/or requirements in support of this
          Agreement include:
        </p>
        <ul>
          <li>Payment for all support costs at the agreed interval.</li>
          <li>
            Reasonable availability of client representative(s) when resolving a
            service related incident or request.
          </li>
          <li>
            Providing InfoArc LLC with the contact details of a minimum of 2
            people who shall be informed of any actions resulting from this
            agreement.
          </li>
          <li>
            Providing InfoArc LLC with a minimum of 2 weeks notice of the
            scheduling of penetration and other testing which carries a risk of
            impacting service.
          </li>
        </ul>
        <h3>2.3 What is required of InfoArc LLC</h3>
        <p>
          InfoArc LLC responsibilities and/or requirements in support of this
          Agreement include:
        </p>
        <ul>
          <li>
            Due diligence in providing 99% Network and Power Availability of
            service(s) for hosting, in accordance with the Service Agreements
            with upstream providers. (See 4.1)
          </li>
          <li>
            Meeting response times associated with service related incidents.
          </li>
          <li>
            Appropriate notification to Client for all scheduled maintenance.
          </li>
          <li>
            Ensuring the hosting facilities and website are secured, in
            accordance with the Service Agreements with upstream providers. (See
            4.1)
          </li>
          <li>
            Security updates and bug fixes are applied within agreed timescales
            for website and hosting and web platforms.
          </li>
        </ul>
        <h3>2.4. Service Assumptions</h3>
        <p>
          Assumptions related to in-scope services and/or components include:
        </p>
        <ul>
          <li>
            A minimum of two full backups per week of the hosting account will
            be carried out.
          </li>
          <li>
            Changes to services will be communicated and documented to the
            client with a minimum notice period of 30 days, in normal
            circumstances this notice will be included in the monthly report.
          </li>
          <li>
            Amendments to site content included in hosting packages will be
            carried out to a maximum of one hour per month unless otherwise
            agreed.
          </li>
        </ul>
        <p>
          This SLA does not cover service related incidents caused by problems
          in the following:
        </p>
        <ul>
          <li>
            Client’s local area network, or the local area networks of the
            Client's customers.
          </li>
          <li>Client-provided Internet connectivity or end-user software.</li>
          <li>
            Entities inside Client’s internal network including, but not limited
            to, firewall configuration and bandwidth shaping, local area
            workstations, or other servers, equipment, and software that have a
            potential bearing on the local networking environment (except web
            browser software).
          </li>
          <li>
            Service requests which require major changes will be a change
            request and chargeable.
          </li>
        </ul>
        <h3>3. Upstream Service Provision</h3>
        <h4>3.1 Linode</h4>
        <p>
          InfoArc LLC Web Hosting servers/operating systems are provided by
          Linode, which is a server/Data Center company. InfoArc LLC maintains
          at least one dedicated multiprocessor Linux server on Linode's service
          with no more than 50% load capacity on this system at any given time.
          Linode advertises 99.5% uptime, and a global network of 11 data
          centers, in which our servers are maintained in their Fremont, CA data
          center.{" "}
        </p>
        <p>
          Our services availability is entirely dependent on the service level
          agreement with Linode, and in the event that Linode services would
          become permanently unavailable for whatever reason, backups of the
          Client's site are retained in another cloud-based service, off the
          Linode infrastructure (See 3.2). More information on their service is
          found here: https://linode.com.
        </p>
        <h4>3.2 Amazon Web Services</h4>
        <p>
          All Client backups are transported to Amazon Web Services S3 storage
          network as they are created, on a 3-day interval. Client site backups
          of the website files and database dump will be made available on
          written or verbal request, and are the property of the Client.
        </p>
        <h3>4. Service Management</h3>
        <p>
          Effective support of in-scope services is a result of maintaining
          consistent service levels. The following sections provide relevant
          details on service availability, monitoring of in-scope services and
          related components.
        </p>
        <h3>4.1 Service Availability</h3>
        <p>
          Coverage parameters specific to the service(s) covered in this
          Agreement are as follows:
        </p>
        <ul>
          <li>
            Telephone support : 9:00 A.M. to 5:00 P.M. Mountain Time, Monday –
            Friday
          </li>
        </ul>
        <p>
          <em>
            Calls received out of office hours will be forwarded to a voicemail
            service and best efforts will be made to action the call, however no
            action can be guaranteed until the next working day
          </em>
        </p>
        <ul>
          <li>
            Email support: Monitored 9:00 A.M. to 5:00 P.M. Mountain Time Monday
            – Friday
          </li>
        </ul>
        <p>
          <em>
            Emails received outside of office hours will be collected, however
            no action can be guaranteed until the next working day
          </em>
        </p>
        <p>
          Any planned maintenance work that we require to carry out that will
          involve either a loss of service, or a risk of loss of service, will
          be advertised at least 2 working days in advance, and will be carried
          out at a time to ensure as minimum an amount of disruption as
          possible.
        </p>
        <h3>4.2. Service Requests</h3>
        <p>
          In support of services outlined in this Agreement, InfoArc LLC will
          respond to service related incidents and/or requests submitted by the
          Client within the following time frames:
        </p>
        <ul>
          <li>
            0-8 hours (during business hours) for issues classified as High
            priority.
          </li>
          <li>Within 48 hours for issues classified as Medium priority.</li>
          <li>Within 5 working days for issues classified as Low priority.</li>
        </ul>
        <p>
          The following definitions will be used to determine the priority of a
          service related incident or request, unless the Service Provider and
          Client agree on an alternative definition:
        </p>
        <div class="tg-wrap">
          <table border="1">
            <thead>
              <tr>
                <td>High</td>
                <td>
                  <ul>
                    <li>Critical security upgrades/patches/bug fixes</li>
                    <li>Downtime investigation and resolution</li>
                    <li>
                      A major function of the hosting service/website is not
                      operational for multiple users
                    </li>
                  </ul>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Medium</td>
                <td>
                  <ul>
                    <li>
                      A minor function of the hosting service/website is not
                      operational for one or more users (who can continue to use
                      other hosting features)
                    </li>
                    <li>
                      A user has questions about the hosting service/website
                      functionality or needs assistance in using the service.
                    </li>
                    <li>A user needs administrative service.</li>
                    <li>Content addition/modification/deletion</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Low</td>
                <td>
                  <ul>
                    <li>Non-critical upgrades e.g. Non critical CMS upgrade</li>
                    <li>
                      Preparation for facilitating client penetration and other
                      testing.
                    </li>
                    <li>Enhancement requests</li>
                    <li>
                      Implementing solutions to technology advances in browsers
                      that may cause disruption to site functionality
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          The priority of any service related incident or request not defined in
          the above table shall be subject to the agreement of the Service
          Provider and Client.
        </p>
      </section>
      <section className="contact-page">
        <article className="contact-form">
          <h3>Policy acceptance form</h3>
          <p className="form-text">
            Please complete the forme below to acknowledge your agreement with
            InfoArc's web hosting policy.
          </p>
          <form action="https://formspree.io/f/mleawkae" method="POST">
            <div class="form-group">
              <span>I agree with InfoArc Web hosting policy</span>
              <br />
              <span>Date:</span>
              <input id="today" type="date" />
              <input
                type="text"
                name="name"
                id="name"
                placeholder="name"
                className="form-control"
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="email"
                className="form-control"
              />
            </div>
            <button type="submit" className="submit-btn btn">
              submit here
            </button>
          </form>
        </article>
      </section>
    </>
  )
}

export default hosting
